<template>
  <el-card class="box-card">
    <div class="title">
      <!-- {{ translateTitle("账户") }} -->
      <Header />
    </div>
    <Headers />
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mt30">
      <el-breadcrumb-item :to="{ path: '/transferMoney' }">{{translateTitle('转账')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{translateTitle('银行账号转账')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="change-form mt30">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-row :gutter="30">
            <el-col :span="12"> 
              <el-form-item :label="translateTitle('转账账户姓名')" prop="accountName">
                <el-input size="large" :placeholder="translateTitle('请输入转账账户姓名')" v-model="ruleForm.accountName" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12"> 
              <el-form-item :label="translateTitle('转账账户')" prop="bankCardNo">
                <el-input size="large" :placeholder="translateTitle('请输入转账账户')" v-model="ruleForm.bankCardNo" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12"> 
              <el-form-item :label="translateTitle('账户类型')" prop="accountType">
                <el-input size="large" disabled :placeholder="translateTitle('请输入账户类型')" v-model="ruleForm.accountType" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12"> 
              <el-form-item :label="translateTitle('赞助银行')" prop="bankName">
                <el-input size="large" disabled :placeholder="translateTitle('请输入赞助银行')" v-model="ruleForm.bankName" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" style="display: none;">
              <el-form-item :label="translateTitle('创建日期')">
                <el-date-picker
                  style="width:100%"
                  v-model="ruleForm.dateOfBirth"
                  disabled
                  clearable
                  format="yyyy-MM-dd"
                  :placeholder="translateTitle('选择日期')"
                  type="date"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('数量')" prop="amount">
                    <el-input :placeholder="translateTitle('请输入数量')" @input="changeAmount" size="large" v-model="ruleForm.amount" clearable class="input-with-select" >
                      <template slot="append">USD</template>
                    </el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <div class="line2 tr">
                    <span class="left">{{ translateTitle("可用余额") }}</span>
                    <span class="right">&nbsp;<span class="mr5">$</span>{{ BalanceCoinNam }} USD</span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="translateTitle('费用')" >
                <el-input :placeholder="translateTitle('请输入费用')" disabled size="large" v-model="transferAmount" clearable class="input-with-select" >
                  <template slot="append">USD</template>
                </el-input>
              </el-form-item>
            </el-col>
            
            <el-col :span="24" class="tr">
             
          <div class="tr">
               <el-button class="sure-btn" @click="onSure('ruleForm')">{{
              translateTitle("转账")
            }}</el-button>
          </div>
            </el-col>
          </el-row>
          
        </el-form>
      </div>
    
  </el-card>
</template>

<script>
import {
  coinNames,
  getBalanceCoinNam,
  userBankBalance,
  coinLink,
  coinBalanceUSD,
  getTransferRate,
  calculateCharge
} from "@/api/index";
import Headers from "../../components/accountHeader/index.vue";
import { coin_transfer, bank_transfer,bank_card_transfer } from "@/api/accountManage";
import { translateTitle } from "@/utils/i18n";
import {multiply,subtr} from '@/utils/date'
export default {
  components: { Headers },
  data() {
    const validateAmount = (rule, value, callback) => {
      const num= /(^[0-9]\d*(\.\d{1,100})?$)|(^0(\.\d{1,100})?$)/;
      if (!num.test(value)) {
        callback(new Error(this.translateTitle("请输入正确格式")))
      }else{
        callback()
      }
    }
    
    return {
      value1: false,
      img1: require("@/assets/imgs/icon_guoneiyinhang.png"),
      img2: require("@/assets/imgs/icon_guojizhanghu.png"),
      img3: require("@/assets/imgs/icon_chanpin.png"),
      loading1: false,
      visible: false,
      checked: false,
      isCheck: "1",
      ruleForm: {
        bankName: 'IBAN',
        accountType: 'PersonalChecking',
        email: "",
        amount: "",
        accountName: '',
        coinName: "USD",
        coinLink: "USD",
        bankCardNo: '',
        dateOfBirth: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + (new Date().getDate()),
      },
      choiceCoin: {
        coinName: 'USD',
        coinLogo: "USD",
      },
      rules: {
        amount: [
          {
            required: true,
            message: this.translateTitle("请输入数量"),
            trigger: "change",
          },
          { required: true, validator: validateAmount, trigger: "change" }
        ],
        accountName: [{required: true,message: this.translateTitle("请输入转账账户姓名"),trigger: "change",},],
        accountType: [{required: true,message: this.translateTitle("请输入账户类型"),trigger: "change",},],
        bankName: [{required: true,message: this.translateTitle("请输入赞助银行"),trigger: "change",},],
        bankCardNo: [{required: true,message: this.translateTitle("请输入转账账户"),trigger: "change",},],
      },
      coinNameData: [],
      BalanceCoinNam: "",
      CoinLinkOption: [],
      btnloading: false,
      transferRate: '',
      transferAmount: '',
      globalLoading: null,
    };
  },
  created() {
    this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
    userBankBalance().then((res) => {
      if(res.code == 200) {
        this.BalanceCoinNam = Number(res.data.amount).toFixed(2);
        this.transfer()
      } else {
        this.$message.error(this.translateTitle(res.msg));
      }
    }).finally(()=> {
      this.globalLoading.close()
    });
    this.getCoinName()
  },
  watch: {
    // 'ruleForm.amount': function (params) {
    //   if (params == '') { this.transferAmount = 0; } else { this.transferAmount = multiply(this.transferRate,params); }
    // },
  },
  methods: {
    multiply,
    subtr,
    translateTitle,
    transfer(val) {},
    changeAmount(params) {
     if (params == '') { this.transferAmount = ''; } else {
       this.getTransferAmount(params)
          }
    },
    getTransferAmount(val) {
      let params = {
         coinLink: this.ruleForm.coinLink,
         coinName: this.ruleForm.coinName,
         inputAmount: val || 0,
         type: '0'
       }
       calculateCharge(params).then(res => {
          this.transferAmount = res.data.charge
       })
    },
    getBalanceCoin(type) {
      if (type == "1") {
        getBalanceCoinNam(this.ruleForm.coinName).then((res) => {
          if(res.code == 200) {
            this.BalanceCoinNam = Number(res.data.amount).toFixed(2);
          } else {
            this.$message.error(this.translateTitle(res.msg));
          }
        });
      } else {
        if (this.ruleForm.coinName && this.ruleForm.coinLink) {
          let params = {
            coinName: this.ruleForm.coinName,
            coinLink: this.ruleForm.coinLink,
          };
          coinBalanceUSD(params).then((res) => {
            if(res.code == 200) {
              this.BalanceCoinNam1 = res.data.balance;
            } else {
              this.$message.error(this.translateTitle(res.msg));
            }
          });
        }
      }
    },
    async getCoinLink(val) {
      let res = await coinLink(val);
      this.CoinLinkOption = res.data;
    },
    async getCoinName(val) {
      this.getCoinLink(this.ruleForm.coinName);
      this.getBalanceCoin(val);
    },
    // changeCoinName(data) {
    //   let obj  = this.coinNameData.find(item => item.coinName === data)
    //   this.choiceCoin.coinName = obj.coinName;
    //   this.choiceCoin.coinLogo = obj.coinLogo;
    //   this.ruleForm.coinName = obj.coinName;
    //   this.ruleForm.coinLink = "";
    //   this.getCoinLink(this.ruleForm.coinName);
    //   this.getBalanceCoin(2);
    // },
    onSure(formName) {
      console.log(123312)
        let _resquest;
        let params;
          _resquest = bank_card_transfer;
          params = {
            amount: this.ruleForm.amount,
            bankCardNo: this.ruleForm.bankCardNo,
            accountName: this.ruleForm.accountName,
            accountType: this.ruleForm.accountType,
            bankName: this.ruleForm.bankName,
            email: this.ruleForm.email,
          };
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.btnloading = true
            this.globalLoading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
            _resquest(params).then((res) => {
              if (res.code == 200) {
                this.$message.success(this.translateTitle("操作成功"));
                // this.getContact()
                this.visible = false;
                this.$refs["ruleForm"].resetFields();
              } else {
                this.$message.error(this.translateTitle(res.msg));
              }
            }).finally(() => {
              // this.btnloading = false
              this.globalLoading.close()
            });
          } else {
            return false;
          }
        });
    },
    onClose() {
      this.$refs["ruleForm"].resetFields();
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 26px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-bottom: 20px;
  text-align: right;
}
.change-form {
  .el-col-12 {
      margin-bottom: 30px;
  }
  .sure-btn {
  background-color: #165BA4;
  border-radius: 12px;
  width: 208px;
height: 48px;
  color: #fff;
  font-weight: 400;
}
}
</style>
